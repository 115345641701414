.ql-snow .ql-stroke {
  stroke: white;
}

.quill {
  color: #fff;
}

.ql-container {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.react-datepicker {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.react-datepicker__day {
  transition: 160ms all;
}

.react-datepicker__day--today {
  background: #fff;
  color: #000;
}

.react-datepicker__day--today:hover {
  background: var(--primary-color);
}

.react-datepicker__day--selected {
  background: var(--primary-color);
  color: #000;
  font-weight: 900;
}

.react-datepicker__day:hover {
  background: var(--primary-color);
}

img {
  pointer-events: none;
}

html{
  -webkit-user-select: none; /* Disable text selection (for Webkit) */
  user-select: none; /* Disable text selection (standard syntax) */
}
